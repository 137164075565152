// jQuery:
import $ from 'jquery';
window.$ = window.jQuery = $;
import toggleCaption from './js/captions.mjs';
// Velocity Animations
// import Velocity from 'velocity-animate';
// import 'velocity-animate/velocity.js';

// import 'magnific-popup';
// import 'magnific-popup/dist/magnific-popup.css'

// fait styles:
import './scss/autoload.scss';

// import linkbar from './js/linkbar';

// <!-- Add the slick-theme.css if you want default styling -->
// <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.css"/>
// <!-- Add the slick-theme.css if you want default styling -->
// <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css"/>

(function($){
  $(document).ready(function(){

    let $navicon = $('#icon'),
      $menuscreen = $('#menuscreen'),
      scrollYPos = window.scrollY;

    $($navicon).click(function(){
  		$(this).toggleClass('open');
      $($menuscreen).toggleClass('open');
  	});

    // Eye-Able
        window.eyeAble_pluginConfig = {"topPosition":"16px","rightPosition":"16px","mobileBottomPosition":"15%","mobileRightPosition":"10px","sidePosition":"right","iconSize":"50px","mobileIconSize":"40px","customIconColor":"#000000","customBackgroundColor":"#1621bb","maxMagnification":12,"blacklistContrast":".logo","mainIconAlt":2,"license":{"kunsthaus-dahlem.de":"403acf4e78ee312aepknnhclj"},"customFunctionPosition":{"Bluefilter":"Main","Darkmode":"Main","TabNavi":"Main","ColorCorrection":"Main","HideImages":"Main","LargeCursor":"More","StopAnimation":"More"}}

        $(window).on('load', function(){
          import('./eyeable/js/eyeAble.js');
        });


    // Open Menu Desktop
    var $header = $('header');
    var $openHeader = $('#open-header'),
        $subMenu = $header.find('#navbar-subnav');

    $openHeader.click(e => {
      $subMenu.slideToggle(400, function(){
        setTimeout(function(){
          window.isScrolled = false;
          console.log(window.isScrolled);
        }, 900)
      });
      $header.toggleClass('open');
    })

    // Scrolldown Recognition
    var $logo = $('#logo'),
        $logoheight = $logo.innerHeight(),
        $isHome = $('body').hasClass('home'),
        $isSubpage = ($('body').hasClass('page') || $('body').hasClass('single')) && !$isHome;

    window.isScrolled = false;
    if($isHome){
      $(window).on('scroll', x => {
        // console.log(window.scrollY);
        if(window.scrollY >= 30){
          if(!$('body').hasClass('logo-shrink')) {
            $('body').toggleClass('logo-shrink');
            $header.toggleClass('scrolled');
          }
        }
        // else if(scrollYPos == 0) {
        //   if($('body').hasClass('logo-shrink')){
        //     $('body').toggleClass('logo-shrink');
        //     $header.toggleClass('scrolled');
        //   }
        // }
      })
    } else if($isSubpage) {
      $(window).on('scroll', x => {
        setTimeout(function(){
          if(!window.isScrolled) {
            $header.addClass('scrolled');
            $subMenu.slideUp();
            $header.removeClass('open');
            window.isScrolled = true;
          }
        }, 200)

        // scrollYPos = window.scrollY;

        // if(window.scrollY - scrollYPos >= 20){
        //
        // }
      })
    }

    // Newsletter Modal
    var $newsletterButton = $('.newsletter'),
        $newsletterModal = $('.newsletter-modal'),
        $newsletterClose = $newsletterModal.find('.close');
    $newsletterButton.click(e => {
      $newsletterModal.toggleClass('d-none')
    })
    $newsletterClose.click(e => {
      $newsletterModal.addClass('d-none')
    })




    // Open Menu Mobile
    var $toggleButton = $('#toggle-menu'),
        $toggledMenu = $('#menuscreen');
        console.log($toggleButton);
    $toggleButton.click(e => {
      console.log($toggledMenu);
      $toggledMenu.slideToggle();
      $header.toggleClass('open');
    })

    // Toggle Menu
    var $navbarMobile = $('#navbar-mobile');
    console.log($navbarMobile.find('.dropdown-toggle'));
    $navbarMobile.find('.dropdown-toggle').each((i, e) => {
      // $(this).css('border', '1px solid black');
      $(e).click(function(e){
        $(this).next('ul').slideToggle();
        $(this).toggleClass('subnav-open');
      })
    });

    // Lozad
    const $lozad = document.getElementsByClassName('lozad');
    if($lozad.length){
      import('lozad').then(i => {
        const observer = lozad(); // lazy loads elements with default selector as '.lozad'
        observer.observe()
      })
    }

    // Scrolldown
    const $scrolldown = $(document.getElementById('scrolldown'));
    $scrolldown.click(e => {
      // const $headerwrapper = $(document.getElementById('headerwrapper'));
      // console.log($headerwrapper.next().offset().top);
      console.log(document.getElementById('headerwrapper').nextElementSibling.offsetTop);
      const offsetJumpTo = document.getElementById('headerwrapper').nextElementSibling.offsetTop;
      $('body, html').animate({
        'scrollTop': offsetJumpTo-200
      }, 800)
      // Velocity(document.getElementById('headerwrapper').nextElementSibling, 'scroll', {
      //   duration: 800,
      //   offset: -200,
      // });
      e.preventDefault();
    })

    const $anchor = $('.anchor');


    // Slider
    var slider = $('.slickslider');
    if(slider.length){
      import('./js/slickslider.mjs').then(file => file.sliders(slider));
    }

    let $banner = $('#banner');
    $(banner).find('.bildbox > a').on('mouseover', (event) => {
      toggleCaption(this)
    })
    $(banner).find('.bildbox > a').on('mouseout', (event) => {
      toggleCaption(this)
    })

    // linkbar.init('.hamburger-icon', '#languages');

    function toggleAccordion(link){
      const _this = link;
      let $openId = $(_this).data('accopenid');
      let $vault = $('.acc-vault.'+$openId);
      // console.log('accoopenid', $(this).data());
      // console.log('find', '#'+$openId);
      // console.log($vault);
      // console.log(this);
      if(!$(_this).hasClass('open')){
        $vault.show();
        $(_this).toggleClass('open');
      } else {
        $(_this).removeClass('open');
        $vault.hide();
      }
    }
    let $accKeys = document.querySelectorAll('.acc-key');
    if($accKeys.length){
      let $accLinks = $($accKeys).find('a[href^="#"]');

      $accLinks.click(function(e){
        toggleAccordion(this);
        e.preventDefault()
      })
    }
  })
})(jQuery)
